import { Box, Heading, HStack, Icon, Link } from '@chakra-ui/react'
import RightArrowIcon from '@material-design-icons/svg/sharp/keyboard_arrow_right.svg?react'

import { Link as WouterLink } from 'wouter'

interface Props {
  defectPageUrl: string
  title: string
}

export function RunApiDefectPreviewTitle({ defectPageUrl, title }: Props) {
  return (
    <Box flexShrink="1" display="flex">
      <Link as={WouterLink} to={defectPageUrl}>
        <HStack gap={0}>
          <Heading variant="cardHeading" textOverflow="ellipsis">
            {title}
          </Heading>
          <Icon as={RightArrowIcon} boxSize={8} />
        </HStack>
      </Link>
    </Box>
  )
}
