import { Card, CardBody, CardHeader, Flex, Heading, Icon, Stack, Text } from '@chakra-ui/react'
import ArrowIcon from '@material-design-icons/svg/sharp/trending_flat.svg?react'

import { DefectsTag } from '@/features/defect-tag/DefectsTag'

interface Props {
  observedHigh: number
  observedMedium: number
  observedLow: number
  reportedHigh: number
  reportedMedium: number
  reportedLow: number
}

export function DsbomSeverityBreakdown({ observedHigh, observedMedium, observedLow, reportedHigh, reportedMedium, reportedLow }: Props) {
  return (
    <Card minWidth={64}>
      <CardHeader>
        <Heading variant="cardHeading">Defect Severity</Heading>
      </CardHeader>
      <CardBody padding={4}>
        <Flex justify="stretch" align="center" height="full" width="full">
          <Stack spacing={2} width="full">
            <Flex direction="row" justifyContent="space-between" alignItems="center">
              <Text fontSize="sm">High</Text>
              <Flex gap={2} align="center">
                <DefectsTag severity="High" count={reportedHigh} tooltipText="Reported" />
                <Icon as={ArrowIcon} />
                <DefectsTag severity="High" count={observedHigh} tooltipText="Observed" />
              </Flex>
            </Flex>
            <Flex direction="row" justifyContent="space-between" alignItems="center">
              <Text fontSize="sm">Med</Text>
              <Flex gap={2} align="center">
                <DefectsTag severity="Medium" count={reportedMedium} tooltipText="Reported" />
                <Icon as={ArrowIcon} />
                <DefectsTag severity="Medium" count={observedMedium} tooltipText="Observed" />
              </Flex>
            </Flex>
            <Flex direction="row" justifyContent="space-between" alignItems="center">
              <Text fontSize="sm">Low</Text>
              <Flex gap={2} align="center">
                <DefectsTag severity="Low" count={reportedLow} tooltipText="Reported" />
                <Icon as={ArrowIcon} />
                <DefectsTag severity="Low" count={observedLow} tooltipText="Observed" />
              </Flex>
            </Flex>
          </Stack>
        </Flex>
      </CardBody>
    </Card>
  )
}
