import { Badge, BadgeProps, Box, HStack, Tooltip } from '@chakra-ui/react'

import { formatNumber } from '@/util/numbers'

export type Severity = 'low' | 'medium' | 'high'
export type CapitalizedSeverity = 'High' | 'Medium' | 'Low'

export interface Props extends BadgeProps {
  severity?: CapitalizedSeverity
  tooltipText?: string
  label?: string
  spacing?: number
  count: number
}

export const DefectsTag = ({ count, label, tooltipText, severity, onClick, spacing = 1 }: Props) => {
  const formattedLongCount = formatNumber(count, { shorten: false })
  const tooltipTextWithCount = tooltipText ? `${formattedLongCount} ${tooltipText}` : `${formattedLongCount}`

  return (
    <Tooltip label={tooltipTextWithCount}>
      <Badge
        variant={severity ? severity.toLowerCase() : 'null'}
        minW="10"
        maxW="fit-content"
        textAlign="right"
        {...(onClick ? { onClick, cursor: 'pointer' } : {})}
      >
        <HStack gap={spacing}>
          {label && <span>{label}</span>}
          <Box flexGrow={1}>{formatNumber(count)}</Box>
        </HStack>
      </Badge>
    </Tooltip>
  )
}
