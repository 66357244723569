import { Select, SelectProps } from '@chakra-ui/react'

export type UserWorkspaceRole = 'owner' | 'member'
interface Props extends SelectProps {
  defaultValue?: UserWorkspaceRole
  value?: UserWorkspaceRole
}

export default function RoleSelect(props: Props) {
  return (
    <Select placeholder="Select a role..." variant="outline" {...props}>
      <option value="member">Member</option>
      <option value="owner">Owner</option>
    </Select>
  )
}
