import { Card, CardBody, CardHeader, Divider, Link as ChakraLink, HStack, Heading, Skeleton, Stack, Text } from '@chakra-ui/react'

import { Page } from '@/components/Page'
import ListDefaults from '@/components/ListDefaults'
import { MayhemPagination } from '@/components/MayhemPagination'
import { DefectStatusBadge } from '@/features/defect/DefectStatusBadge'
import { useQuery } from '@/hooks'
import { DEFAULT_PER_PAGE } from '@/redux/api/mayhemApi'
import { useGetMdsbomDefectQuery, useGetMdsbomImageRepoQuery, useGetMdsbomWorkspaceReportsQuery } from '@/redux/api/mdsbom'
import { cleanInteger } from '@/util/numbers'

import { DefectSeverityBadge } from '@/features/defect/DefectSeverityBadge'
import { DsbomReportRow } from '@/features/dsbom-reports/DsbomReportRow'
import { ErrorPage } from '@/components/layout/ErrorPage'
import { MayhemBreadcrumb } from '@/components/MayhemBreadcrumb'

interface Props {
  workspaceSlug: string
  imageRepoId: string
  defectId: string
}

export function DsbomDefectPage({ workspaceSlug, imageRepoId, defectId }: Props) {
  const query = useQuery()
  const perPage = cleanInteger(query.get('perPage'), DEFAULT_PER_PAGE)
  const page = cleanInteger(query.get('page'), 1)

  const { data: imageRepoData, isLoading: isImageLoading } = useGetMdsbomImageRepoQuery({ workspace: workspaceSlug, imageRepoId })
  const imageRepoName = imageRepoData?.image_name
  const {
    data: defectData,
    isFetching: isDefectFetching,
    isLoading: isDefectLoading,
    isError,
    error
  } = useGetMdsbomDefectQuery({ workspace: workspaceSlug, imageRepoId, defectId })
  const { title, severity, severity_level: severityLevel, cve_url: cveUrl, observed } = defectData || {}

  const { data: derivedReportSets } = useGetMdsbomWorkspaceReportsQuery({
    workspace: workspaceSlug,
    imageRepoId,
    vulnerabilityCursors: [defectId],
    offset: (page - 1) * perPage,
    perPage
  })

  const { data: lastDerivedReportSets } = useGetMdsbomWorkspaceReportsQuery({
    workspace: workspaceSlug,
    imageRepoId,
    vulnerabilityCursors: [defectId],
    perPage: 1
  })

  const lastDerivedReportSet = lastDerivedReportSets?.reports[0]

  if (isError && error) {
    const goBackLocation = `/${workspaceSlug}/-/dynamic-sbom`
    if ('status' in error) {
      if (error.status === 404) {
        return <ErrorPage errorCode={404} errorMessage="We couldn't find the specified MDSBOM defect" goBackLocation={goBackLocation} />
      } else {
        return <ErrorPage errorMessage="An error occurred while fetching the MDSBOM defect" goBackLocation={goBackLocation} />
      }
    }

    return <ErrorPage errorMessage="An error occurred while fetching the MDSBOM defect" goBackLocation={goBackLocation} />
  }

  return (
    <Page
      isLoading={isDefectLoading}
      header={
        <MayhemBreadcrumb
          isLoading={isImageLoading}
          items={[
            { text: 'Dynamic SBOM', path: `/${workspaceSlug}/-/dynamic-sbom` },
            { text: imageRepoName || '', path: `/${workspaceSlug}/-/dynamic-sbom/${imageRepoId}`, isBadge: true, badgeTooltip: 'Image' },
            { text: `Defect ${defectId}` }
          ]}
        />
      }
    >
      <Stack spacing={4}>
        <Skeleton isLoaded={!isDefectLoading}>
          <Stack>
            <HStack>
              <Heading size="xl">{title}</Heading>
              <DefectSeverityBadge severityLevel={severityLevel?.toLowerCase() as 'high' | 'medium' | 'low'} severity={severity} />
              <Text color="faded">Defect {defectId}</Text>
              <DefectStatusBadge state={observed ? 'open' : 'closed'} />
            </HStack>
            {cveUrl && (
              <ChakraLink href={cveUrl} isExternal>
                {cveUrl}
              </ChakraLink>
            )}
            <Divider />
          </Stack>
        </Skeleton>
        <Skeleton isLoaded={!isDefectLoading}>
          <Card>
            <CardHeader>
              <Heading variant="cardHeading">Last seen in (from Report {lastDerivedReportSet?.id})</Heading>
            </CardHeader>
            <CardBody padding={4}>
              <Text>
                <b>Image: </b>
                {lastDerivedReportSet?.image_references}
              </Text>
            </CardBody>
          </Card>
        </Skeleton>
        <Card>
          <CardHeader>
            <Heading variant="cardHeading">Reports</Heading>
            <Text color="faded">Reports in which the CVE appears in</Text>
          </CardHeader>
          <CardBody>
            {!isDefectFetching &&
              derivedReportSets?.reports.map((reportSet) => <DsbomReportRow key={reportSet.id} workspaceSlug={workspaceSlug} report={reportSet} />)}
            <ListDefaults isLoading={isDefectFetching} nItems={derivedReportSets?.count || 0} itemName="report" />
          </CardBody>
          <MayhemPagination isLoading={isDefectFetching} total={derivedReportSets?.count || 0} />
        </Card>
      </Stack>
    </Page>
  )
}
