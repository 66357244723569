import { popoverAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  body: {
    bg: 'chakra-body-bg',
    borderRadius: 'md',
    paddingX: 4,
    paddingY: 4
  },
  content: {
    bg: 'chakra-body-bg'
  }
})

export const Popover = defineMultiStyleConfig({ baseStyle })
