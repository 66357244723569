import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['internal'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      searchWorkerPools: build.query<SearchWorkerPoolsApiResponse, SearchWorkerPoolsApiArg>({
        query: (queryArg) => ({ url: `/api/v2/worker-pool`, params: { offset: queryArg.offset, per_page: queryArg.perPage } }),
        providesTags: ['internal']
      }),
      createWorkerPool: build.mutation<CreateWorkerPoolApiResponse, CreateWorkerPoolApiArg>({
        query: (queryArg) => ({ url: `/api/v2/worker-pool`, method: 'POST', body: queryArg.workerPoolUpsertRequest }),
        invalidatesTags: ['internal']
      }),
      getWorkerPool: build.query<GetWorkerPoolApiResponse, GetWorkerPoolApiArg>({
        query: (queryArg) => ({ url: `/api/v2/worker-pool/${queryArg.workerPoolId}` }),
        providesTags: ['internal']
      }),
      updateWorkerPool: build.mutation<UpdateWorkerPoolApiResponse, UpdateWorkerPoolApiArg>({
        query: (queryArg) => ({ url: `/api/v2/worker-pool/${queryArg.workerPoolId}`, method: 'PUT', body: queryArg.workerPoolUpsertRequest }),
        invalidatesTags: ['internal']
      }),
      deleteWorkerPool: build.mutation<DeleteWorkerPoolApiResponse, DeleteWorkerPoolApiArg>({
        query: (queryArg) => ({ url: `/api/v2/worker-pool/${queryArg.workerPoolId}`, method: 'DELETE' }),
        invalidatesTags: ['internal']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type SearchWorkerPoolsApiResponse = /** status 200
    Returns a list of worker pools.
    :param offset: the offset of the results to retrieve, 0-indexed
    :param per_page: the number of results to retrieve per page
     */ WorkerPoolListingResponse
export type SearchWorkerPoolsApiArg = {
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type CreateWorkerPoolApiResponse = /** status 200
    Creates a worker pool.
     */ WorkerPoolGetResponse
export type CreateWorkerPoolApiArg = {
  workerPoolUpsertRequest: WorkerPoolUpsertRequest
}
export type GetWorkerPoolApiResponse = /** status 200
    Returns a worker pool.
     */ WorkerPoolGetResponse
export type GetWorkerPoolApiArg = {
  workerPoolId: number
}
export type UpdateWorkerPoolApiResponse = /** status 200 Updates a worker pool. */ WorkerPoolGetResponse
export type UpdateWorkerPoolApiArg = {
  workerPoolId: number
  workerPoolUpsertRequest: WorkerPoolUpsertRequest
}
export type DeleteWorkerPoolApiResponse = /** status 200  "Deletes a worker pool. */ Ok
export type DeleteWorkerPoolApiArg = {
  workerPoolId: number
}
export type Workspace = {
  slug: string
  name: string | null
  is_org: boolean
}
export type WorkerPoolGetResponse = {
  worker_pool_id: number
  name: string
  max_workers: number
  workspaces: Workspace[]
  last_active_at: string | null
}
export type WorkerPoolListingResponse = {
  results: WorkerPoolGetResponse[]
  page_size: number
  page_number: number
  total: number
}
export type ApiError = {
  message?: string
}
export type WorkerPoolUpsertRequest = {
  name: string
  max_workers: number
}
export type Ok = {
  result?: string
}
