import { Badge, Link, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Stack, Text } from '@chakra-ui/react'

export type SeverityLevel = 'HIGH' | 'MEDIUM' | 'LOW'

export type SeverityLevelLowerCase = 'low' | 'medium' | 'high'

interface DefectSeverityBadgeProps {
  severityLevel?: SeverityLevelLowerCase
  severity?: number | null
}

export function DefectSeverityBadge({ severityLevel = 'low', severity }: DefectSeverityBadgeProps) {
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Badge variant={`solid-${severityLevel}`} zIndex={1}>
          {severityLevel} ({severity !== null ? severity : '*'})
        </Badge>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Stack gap={4}>
            {severity === null ? (
              'Unknown Severity Rating'
            ) : severity === undefined ? (
              ''
            ) : (
              <Stack gap={2} fontWeight="semibold" textTransform="capitalize">
                <Text>Severity Level: {severityLevel}</Text>
                <Text>Severity Rating: {severity}</Text>
              </Stack>
            )}
            <Text>
              <Text>
                Mayhem calculates the severity for a defect by taking the average{' '}
                <Link isExternal href="https://nvd.nist.gov/vuln-metrics/cvss" textDecoration="underline">
                  CVSS
                </Link>{' '}
                score for the defect’s CWE type from the last year of vulnerabilities seen in the{' '}
                <Link isExternal href="https://nvd.nist.gov/" textDecoration="underline">
                  National Vulnerability Database
                </Link>
                .
              </Text>
            </Text>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
