import { useEffect } from 'react'

import { Stack } from '@chakra-ui/react'

import { Page } from '../../components/Page'
import { useGetDefectByTargetQuery } from '../../redux/api/defects'
import { getDefectPageTitle } from '../../titles'

import { ErrorPage } from '../../components/layout/ErrorPage'

import { CodeDefectSummary } from './CodeDefectSummary'
import { DefectsRelatedTestCases } from './DefectsRelatedTestCases'
import { DefectsRelatedValgrindErrors } from './DefectsRelatedValgrindErrors'
import { RestIssue } from './RestIssue'

import { MayhemBreadcrumb } from '@/components/MayhemBreadcrumb'
import { useGetProjectQuery } from '@/redux/api/projects'
import { useGetTargetQuery } from '@/redux/api/targets'

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  defect: string
  runNumber: number | null | undefined
}

export function DefectPage({ workspaceSlug, projectSlug, targetSlug, defect: defectNumberParam, runNumber }: Props) {
  let defectNumber: number
  try {
    defectNumber = Number.parseInt(defectNumberParam, 10)
  } catch {
    throw new Error(`Invalid defect number: ${defectNumberParam}`)
  }

  const { data: project, isLoading: isProjectLoading } = useGetProjectQuery({ owner: workspaceSlug, projectSlug })
  const { owner_name: workspaceName, project_name: projectName } = project || {}

  const { data: target, isLoading: isTargetLoading } = useGetTargetQuery({ owner: workspaceSlug, projectSlug, targetSlug })
  const { target_name: targetName } = target || {}

  const {
    data: defect,
    isLoading: isDefectLoading,
    isError,
    error
  } = useGetDefectByTargetQuery({ owner: workspaceSlug, projectSlug, targetSlug, defectNumber })
  useEffect(() => {
    if (workspaceName && projectName && targetName) {
      document.title = getDefectPageTitle(workspaceName, projectName, targetName, defectNumberParam)
    }
  }, [workspaceName, projectName, targetName, defectNumber, defectNumberParam])

  const isMapiIssue = defect?.type === 'mapi' || defect?.type === 'zap'
  const isCodeIssue = defect?.type === 'crash' || defect?.type === undefined || defect?.type === 'valgrind'
  const isValgrindIssue = defect?.type === 'valgrind' || defect?.from_valgrind

  if (isError && error) {
    const goBackLocation = `/${workspaceSlug}`
    if (error && 'status' in error) {
      if (error.status === 404) {
        return <ErrorPage errorCode={404} errorMessage="We couldn't find the specified defect" goBackLocation={goBackLocation} />
      } else {
        return <ErrorPage errorMessage="An error occurred while fetching the defect" goBackLocation={goBackLocation} />
      }
    }

    return <ErrorPage errorMessage="An error occurred while fetching the defect" goBackLocation={goBackLocation} />
  }

  return (
    <Page
      isLoading={isDefectLoading}
      header={
        <MayhemBreadcrumb
          isLoading={isProjectLoading || isTargetLoading}
          items={[
            { text: 'Projects', path: `/${workspaceSlug}/-/projects` },
            { text: projectName!, path: `/${workspaceSlug}/${projectSlug}` },
            {
              text: targetName!,
              path: `/${workspaceSlug}/${projectSlug}/${targetSlug}`,
              isBadge: true,
              badgeTooltip: 'Target',
              hideSeparator: true
            },
            {
              text: `Defect #${defectNumber}`
            }
          ]}
        />
      }
      // Extra bottom padding to ensure the bell doesn't cover tooltips
      paddingBottom={24}
    >
      <Stack spacing={8}>
        {isCodeIssue && (
          <CodeDefectSummary
            workspaceSlug={workspaceSlug}
            projectSlug={projectSlug}
            targetSlug={targetSlug}
            runNumber={runNumber}
            defectNumber={defectNumber}
          />
        )}
        {isCodeIssue && !isValgrindIssue && (
          <DefectsRelatedTestCases workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} defectNumber={defectNumber} />
        )}
        {isValgrindIssue && (
          <DefectsRelatedValgrindErrors workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} defectNumber={defectNumber} />
        )}
        {isMapiIssue && (
          <RestIssue
            workspaceSlug={workspaceSlug}
            projectSlug={projectSlug}
            targetSlug={targetSlug}
            defectNumber={defectNumber}
            runNumber={runNumber}
          />
        )}
      </Stack>
    </Page>
  )
}
