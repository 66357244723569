import { HStack, IconButton, Skeleton, Tooltip } from '@chakra-ui/react'

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

import { memo } from 'react'

import { Link } from 'wouter'

import { useGetDefectNavigationQuery } from '../../redux/api/defects'

interface DefectNavigationProps {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  defectNumber: number
  runNumber: number | null | undefined
}

function DefectNavigationInner({ workspaceSlug, projectSlug, targetSlug, runNumber, defectNumber }: DefectNavigationProps) {
  const { data: navigationData, isFetching } = useGetDefectNavigationQuery({
    owner: workspaceSlug,
    projectSlug,
    targetSlug,
    runNumber: runNumber ? runNumber : undefined,
    defectNumber
  })

  if (!navigationData) {
    return <></>
  }

  const previousLink =
    `/${workspaceSlug}/${projectSlug}/${targetSlug}/-/defects/${navigationData?.previous_defect_number}` + (runNumber ? `/runs/${runNumber}` : '')

  const nextLink =
    `/${workspaceSlug}/${projectSlug}/${targetSlug}/-/defects/${navigationData?.next_defect_number}` + (runNumber ? `/runs/${runNumber}` : '')
  return (
    <Skeleton isLoaded={!isFetching}>
      <HStack gap="2">
        {navigationData.previous_defect_number && (
          <Tooltip label="Previous Defect">
            <Link href={previousLink}>
              <IconButton variant="navigation" icon={<ChevronLeftIcon />} aria-label="Previous Defect" />
            </Link>
          </Tooltip>
        )}
        {navigationData.next_defect_number && (
          <Tooltip label="Next Defect">
            <Link to={nextLink}>
              <IconButton variant="navigation" icon={<ChevronRightIcon />} aria-label="Next Defect" />
            </Link>
          </Tooltip>
        )}
      </HStack>
    </Skeleton>
  )
}

export const DefectNavigation = memo(DefectNavigationInner)
